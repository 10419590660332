import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import CaseStudyText from "../../screens/resources/case-study/sti-female-patient-suzanne"

const CaseStudy = () => {
    return (
    <React.Fragment>
      <CaseStudyText />
    </React.Fragment>
  )
}
export default CaseStudy

export const Head = () => (
  <Seo
        title="Patient Case Study 2: Undertreatment of STIs"
        description="Suzanne, a 24-year-old living in a large city, presents to a nearby clinic with recent onset urinary frequency and urgency, and vaginal discharge and irritation."
        image="/meta/case-study-suzanne.jpg"
  />
)
